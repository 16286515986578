<template>
  <div style="position:relative;">
    <div
      v-if="headSlides && headSlides.length > 0 && !isPartner"
      class="background"
      :style="{
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        background: `bottom left no-repeat, linear-gradient(to left, #d20051 0%, #ffa300 100%) `,
      }"
    ></div>
    <div
      v-if="headSlides && headSlides.length > 0"
      class="small-slider-container"
      :style="backgroundStyle"
    >
      <swiper :options="smallSliderOption">
        <swiper-slide v-for="(slide, index) in headSlides" :key="`${slide.bannerId}_${index}`">
          <div class="slider-wrapper" @click="goTo($event, slide, 'redirect')">
            <div class="slider">
              <div class="slider-top-row" :style="{ justifyContent: isLampUser && 'center' }">
                <div
                  class="slider-top-row-left"
                  @click="goToPartners($event, slide)"
                  :style="{ paddingRight: isLampUser && '0' }"
                >
                  <BrandLogo :image="slide.image" :width="50" :height="50" />
                </div>
                <div v-if="slide.isDigitalAlliance && !isLampUser" class="slider-top-row-right">
                  <div class="slider-top-row-left-content">
                    <div
                      class="slider-top-row-header"
                      :style="slide.partner.partnerCode === 'PLADIS' && 'justify-content: center'"
                    >
                      <img src="@/assets/images/icons/truck.svg" alt="truck" class="icon-info" />
                      <VueText
                        sizeLevel="3"
                        weightLevel="3"
                        :color="'grey-30'"
                        style="white-space: nowrap"
                      >
                        {{
                          slide.partner.partnerCode === 'PLADIS'
                            ? 'SONRAKİ ZİYARET (SATIŞ)'
                            : 'SONRAKİ ZİYARET'
                        }}</VueText
                      >
                    </div>

                    <VueText
                      class="slider-top-row-info"
                      sizeLevel="6"
                      weightLevel="3"
                      :color="'grey-40'"
                      :style="slide.partner.partnerCode === 'PLADIS' && 'justify-content: center'"
                      >{{ slide.nextVisit }}</VueText
                    >
                  </div>

                  <div
                    v-if="slide.partner.partnerCode === 'PMI'"
                    :style="{ opacity: !isUserKacc ? 1 : 0 }"
                    class="slider-top-row-right-content"
                    @click="goTo($event, routes.wallets, null, 'cuzdanlarim')"
                  >
                    <div class="slider-top-row-header">
                      <img src="@/assets/images/icons/wallet.svg" alt="wallet" class="icon-info" />

                      <VueText :color="'grey-30'" sizeLevel="3" weightLevel="3">
                        CÜZDAN
                      </VueText>
                    </div>
                    <div class="slider-top-row-info">
                      <VueText sizeLevel="6" weightLevel="3" :color="'grey-40'">
                        {{ slide.amount | currencyFormat }} TL</VueText
                      >
                    </div>
                  </div>
                </div>
                <div v-else-if="!isLampUser" class="slider-top-row-right-desc">
                  <VueText sizeLevel="6" weightLevel="4" :color="'grey-50'">{{
                    slide.title
                  }}</VueText>
                  <VueText sizeLevel="4" weightLevel="3" :color="'grey-30'">{{
                    slide.detail
                  }}</VueText>
                </div>
              </div>

              <div class="slider-bottom-row-actions">
                <BrandButton
                  v-if="slide.partner.partnerCode === 'PMI'"
                  @click="goTo($event, routes.dowin, null, 'yap_kazan')"
                  class="task-btn"
                  :size="constants.size.xSmall"
                  :class="{ purpleButton: slide.taskCount > 0, empty: slide.taskCount <= 0 }"
                  :outlined="false"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="8"
                    aria-labelledby="IconWaveHome"
                    baseProfile="tiny"
                    version="1.2"
                    role="presentation"
                    viewBox="0 0 21 8"
                  >
                    <g :fill="slide.taskCount > 0 ? '#fff' : '#b4c2d3'">
                      <path
                        d="M-.432 4.086c.204-.239.563-.267.802-.063.783.67 1.627 1.18 2.518 1.475l.287.088c1.363.38 2.843.31 4.26-.042l.348-.092c.264-.076.464-.145.584-.193.291-.118.623.023.74.314.118.291-.022.623-.314.74l-.222.083c-.216.076-.508.164-.863.252-1.59.395-3.258.475-4.839.034-.114-.032-.227-.067-.34-.104C1.486 6.23.52 5.647-.369 4.888c-.24-.204-.267-.563-.063-.802zM18.629 4.023c.239-.204.598-.176.802.063.204.239.175.598-.064.802-.889.76-1.856 1.343-2.899 1.69-.111.037-.224.071-.338.103-1.58.442-3.25.362-4.84-.033-.497-.123-.87-.248-1.085-.335-.291-.117-.432-.449-.314-.74.117-.291.449-.432.74-.314.169.068.492.176.933.285 1.416.352 2.896.423 4.26.042.096-.027.191-.056.286-.088.89-.296 1.734-.805 2.519-1.475z"
                        transform="translate(1 1)"
                      ></path>
                      <path
                        d="M3.442.28C4.7-.87 6.638-.843 7.862.31L8 .45 9.423 1.95h.151l1.422-1.5C12.164-.791 14.1-.907 15.407.154l.148.128c.066.06.123.116.17.168 1.045 1.102 1.249 2.736.531 4.045-.031.058-.068.118-.108.178-.63.958-1.568 1.664-2.634 1.97-.515.147-1.033.204-1.552.15-.312-.033-.54-.312-.507-.625.033-.312.313-.539.625-.506.365.038.739-.003 1.12-.112.8-.23 1.515-.768 2-1.504l.057-.095c.483-.88.346-1.977-.363-2.725-.03-.032-.064-.066-.107-.105-.805-.738-2.044-.73-2.841-.008l-.123.12-1.592 1.68c-.086.09-.199.149-.32.169l-.093.007H9.18c-.125 0-.246-.04-.344-.115l-.069-.062L7.173 1.23C6.386.393 5.058.344 4.21 1.12l-.113.112c-.703.741-.84 1.837-.36 2.713.019.033.04.067.063.103.482.733 1.197 1.27 1.996 1.5.383.11.757.15 1.122.113.312-.033.592.194.625.506.032.313-.195.592-.507.625-.519.054-1.037-.003-1.553-.15-1.066-.306-2.005-1.013-2.631-1.967-.039-.057-.075-.116-.11-.179-.718-1.311-.515-2.945.526-4.043.055-.06.113-.116.174-.172z"
                        transform="translate(1 1)"
                      ></path>
                    </g>
                  </svg>
                  <VueText class="do-win-btn-text" color="white-100" sizeLevel="3" weightLevel="3">
                    YAP KAZAN
                  </VueText>
                  <span :class="slide.taskCount ? 'task-count' : 'task-count task-count-0'"
                    ><VueText color="grey-50" sizeLevel="4" weightLevel="4">{{
                      slide.taskCount
                    }}</VueText></span
                  >
                </BrandButton>
                <BrandButton
                  v-if="shouldShowSlide(slide)"
                  @click="
                    handleGoTo(
                      $event,
                      slide.partner.partnerCode,
                      slide.partner.description,
                      'hakkinda',
                    )
                  "
                  class="task-btn"
                  :size="constants.size.xSmall"
                  :outlined="true"
                >
                  <VueText sizeLevel="3" weightLevel="3">
                    HAKKINDA
                  </VueText>
                </BrandButton>
                <BrandButton
                  :size="constants.size.xSmall"
                  :outlined="true"
                  shape="rounded"
                  :iconName="constants.icons.priceListHome.name"
                  :iconSize="{
                    width: constants.icons.priceListHome.width,
                    height: constants.icons.priceListHome.height,
                  }"
                  iconColor="#E5472D"
                  class="doWin-button"
                  :radius="50"
                  @click="
                    handleGoTo(
                      $event,
                      slide.partner.partnerCode,
                      slide.partner.description,
                      'fiyat_listesi',
                    )
                  "
                >
                  <VueText :isSingleLine="true" sizeLevel="3" weightLevel="3">
                    FİYAT LİSTESİ</VueText
                  >
                </BrandButton>
                <!-- <BrandButton
                    @click="goTo($event, routes.playwin)"
                    class="task-btn"
                    :size="sizes.xSmall"
                    :outlined="true"
                  >
                    <img src="@/assets/images/icons/scratchwin.svg" alt="scratch" class="icon-task" />
                    <VueText sizeLevel="3" weightLevel="4">
                      KAZI KAZAN
                    </VueText>
                    <span class="task-count"
                      ><VueText color="grey-50" sizeLevel="4" weightLevel="4">{{
                        slide.instantWinCount
                      }}</VueText></span
                    >
                  </BrandButton> -->
              </div>
            </div>
          </div>
        </swiper-slide>
        <!-- <div class="swiper-pagination" slot="pagination"></div> -->
      </swiper>
    </div>
  </div>
</template>
<script>
import BrandLogo from '@/components/brand/BrandLogo/BrandLogo.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton';
import VueText from '@/components/shared/VueText/VueText.vue';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import RoutesPmi from '@/router/routes/secure/businessPartners/RoutesPmi';
import RoutesUlker from '@/router/routes/secure/businessPartners/RoutesUlker';
import RoutesHorizon from '@/router/routes/secure/businessPartners/RoutesHorizon';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesRoot from '@/router/routes/RoutesRoot';
import { Common } from '@/services/Api/index';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import StorageProps from '@/mixins/storageProps.js';
import currencyFilter from '@/mixins/currencyFilter.js';
import 'swiper/dist/css/swiper.css';
import AccountMixin from '@/utils/accountUtils.js';
import StorageHelper from '@/utils/storageHelper';
import RoutesPeyman from '@/router/routes/secure/businessPartners/RoutesPeyman';
import gtmUtils from '@/mixins/gtmUtils.js';
import { mapGetters } from 'vuex';

export default {
  name: 'BrandPartnerSlider',
  mixins: [StorageProps, currencyFilter, AccountMixin, gtmUtils],
  components: {
    BrandButton,
    VueText,
    swiper,
    swiperSlide,
    BrandLogo,
  },
  props: {},

  data() {
    return {
      headSlides: null,
      config: null,
      smallSliderOption: {
        autoplay: { delay: 5000, disableOnInteraction: true },
        slidesPerView: 1.1,
        centeredSlides: true,
        spaceBetween: 9,
        watchOverflow: true,
        resistance: true,
        resistanceRatio: 0.6,
        grabCursor: true,
      },
    };
  },
  computed: {
    ...mapGetters('app', ['getMainPageBackgroundImage']),
    constants() {
      return {
        size: COMPONENT_CONSTANTS.COMPONENT_SIZES,
        icons: {
          priceListHome: { ...COMPONENT_CONSTANTS.ICON_VARIABLES.priceListHome, color: '#E5472D' },
        },
      };
    },
    routes() {
      return {
        dowin: `${RoutesRoot.Secure.path}/${RoutesSecure.DoAndWin.path}`,
        playwin: `${RoutesRoot.Secure.path}/${RoutesSecure.PlayAndWin.path}`,
        wallets: `${RoutesRoot.Secure.path}/${RoutesSecure.MyWallets.path}`,
        pmiPriceList: `${RoutesRoot.Secure.path}/${RoutesSecure.BusinessPartners.path}/pmi/${RoutesPmi.PriceList.path}`,
        pladisPriceList: `${RoutesRoot.Secure.path}/${RoutesSecure.BusinessPartners.path}/ulker/${RoutesUlker.List.path}`,
        peymanPriceList: `${RoutesRoot.Secure.path}/${RoutesSecure.BusinessPartners.path}/peyman/${RoutesPeyman.List.path}`,
        horizonPriceList: `${RoutesRoot.Secure.path}/${RoutesSecure.BusinessPartners.path}/horizon/${RoutesHorizon.List.path}`,
        partnersPmi: `${RoutesRoot.Secure.path}/${RoutesSecure.BusinessPartners.path}/pmi`,
        partnersUlker: `${RoutesRoot.Secure.path}/${RoutesSecure.BusinessPartners.path}/ulker`,
        partnersPeyman: `${RoutesRoot.Secure.path}/${RoutesSecure.BusinessPartners.path}/peyman`,
        partnersHorizon: `${RoutesRoot.Secure.path}/${RoutesSecure.BusinessPartners.path}/horizon`,
      };
    },
    backgroundStyle() {
      return this.isPartner && this.getMainPageBackgroundImage
        ? {
            background: `url('${this.getMainPageBackgroundImage}')`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'top center',
            backgroundSize: '100% 100%',
            width: '100%',
            height: '100%',
            maxWidth: '100%',
            maxHeight: '700px',
          }
        : '';
    },
  },
  created() {
    Common.getPartnerBanners(this.customerCode).then(res => {
      if (res.data && res.data.Data) {
        if (res.data.Data.config) {
          this.config = res.data.Data.config;
          this.setSwiperOptions(this.config);
        }
        let count = res.data.Data.partnerBanners[0].taskCount;
        let vvBadge = res.data.Data.partnerBanners[0].hasVirtualVisit;
        this.$store.dispatch('app/setActiveDowinCount', count);
        new StorageHelper({
          id: process.env.VUE_APP_STORAGE_TASK_COUNT,
        }).set(count);
        this.$store.dispatch('app/setVvBadge', vvBadge);
        new StorageHelper({
          id: process.env.VUE_APP_STORAGE_TASK_COUNT,
        }).set(count);
        this.$store.dispatch('app/setVvBadge', vvBadge);
        new StorageHelper({
          id: process.env.VUE_APP_VIRTUAL_VISIT_BADGE,
        }).set(vvBadge);
        this.headSlides = res.data.Data.partnerBanners;
      }
    });
  },
  methods: {
    handleGoTo(event, partnerCode, description, type) {
      const routesMap = {
        PMI: type !== 'hakkinda' ? this.routes.pmiPriceList : this.routes.partnersPmi,
        PLADIS: type !== 'hakkinda' ? this.routes.pladisPriceList : this.routes.partnersUlker,
        PEY: type !== 'hakkinda' ? this.routes.peymanPriceList : this.routes.partnersPeyman,
        HORIZON: type !== 'hakkinda' ? this.routes.horizonPriceList : this.routes.partnersHorizon,
      };

      const route = routesMap[partnerCode] || this.routes.feed;
      if (window.location.pathname === route) return;
      this.goTo(event, route, null, type, description);
    },
    shouldShowSlide(slide) {
      const partnerCodes = ['PLADIS', 'PEY', 'HORIZON'];
      return partnerCodes.includes(slide.partner.partnerCode);
    },
    setSwiperOptions(options) {
      if (options) {
        if (options.bannerSliderType) {
          if (options.bannerSliderType.type === 3 && options.period > 0) {
            this.smallSliderOption.autoplay.delay = options.period * 1000;
            this.autoPlay = false;
          } else {
            // delete this.smallSliderOption.autoplay;
          }

          if (options.bannerSliderType.type === 1) {
            this.smallSliderOption.resistance = true;
            this.smallSliderOption.resistanceRatio = 0;
          }
        }
      }
    },
    goToPartners(event, item) {
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('business_partner_click', {
        partner: item?.partner?.description,
      });
      if (item.isDigitalAlliance) {
        if (item.partner.partnerCode === 'PMI' && this.$route.path !== this.routes.partnersPmi) {
          event.stopPropagation();
          this.$router.push(this.routes.partnersPmi);
        }
        if (
          item.partner.partnerCode === 'PLADIS' &&
          this.$route.path !== this.routes.partnersUlker
        ) {
          event.stopPropagation();
          this.$router.push(this.routes.partnersUlker);
        }
        if (item.partner.partnerCode === 'PEY' && this.$route.path !== this.routes.partnersPeyman) {
          event.stopPropagation();
          this.$router.push(this.routes.partnersPeyman);
        }
      }
    },
    goTo(event, target, redirect, type, partner) {
      if (type === 'yap_kazan') {
        this.pushDataLayerEvent('ecommerce');
        this.pushDataLayerEvent('yap_kazan', {
          location: 'partner-slider',
        });
      } else if (type === 'hakkinda' || type === 'fiyat_listesi') {
        this.pushDataLayerEvent('ecommerce');
        this.pushDataLayerEvent(type + '_click', {
          location: 'partner-slider',
          partner: partner,
        });
      } else if (type === 'cuzdanlarim') {
        this.pushDataLayerEvent('ecommerce');
        this.pushDataLayerEvent('cuzdanlarim', {
          location: 'partner-slider',
        });
      }
      if (target !== this.$route.path && !(target == this.routes.wallets && this.isUserKacc)) {
        if (target && redirect) {
          if (!target.isDigitalAlliance && target.link) {
            window.location = target.link;
          }
        } else {
          event.stopPropagation();
          this.$router.push(target);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.background {
  height: 60px;
  width: 100%;
  position: absolute;
  top: 0;
  background-size: cover;
}
.small-slider-container {
  background-color: #f8f9f9;
  .container-inner {
    background: transparpent;
    padding: 0 15px 15px 15px;
    .swiper-container {
      width: 100%;
      margin: 0 auto 20px auto;
    }
  }

  .swiper-slide {
    // width: 335px;
    margin-bottom: 15px;
    border-radius: 4px;

    box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.18);
    background-color: #ffffff;
    height: 120px;
    max-height: 120px;
  }

  .slider-wrapper {
    height: 100%;
    padding: 0 palette-space-level(10);
    .slider {
      height: 100%;
      padding: 10px 0;
      opacity: 0.99;
      flex-direction: column;
      display: flex;
      .slider-top-row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        flex: 1;
        .slider-top-row-left {
          display: flex;
          padding-right: palette-space-level(10);
        }
        .slider-top-row-right-desc {
          width: 100%;
          flex-direction: column;
          word-break: break-word;
          p {
            &:first-child {
              margin-bottom: palette-space-level(5);
            }
          }
        }
        .slider-top-row-right {
          width: 100%;
          display: flex;
          flex-direction: row;

          .slider-top-row-right-content,
          .slider-top-row-left-content {
            flex-direction: column;
            justify-content: flex-start;
            padding-top: 4px;
            flex: 1;
            margin-left: 20px;
            cursor: pointer;
            .slider-top-row-info {
              display: flex;
              .icon-info {
                margin-right: 3px;
              }
            }
            .slider-top-row-header {
              margin-bottom: 3px;
              display: flex;
              flex-direction: row;
              .icon-info {
                margin-right: 3px;
                width: 12px;
                height: 12px;
              }
            }
            @media screen and (max-width: 370px) {
              .slider-top-row-header {
                p {
                  font-size: palette-font-size-level(2);
                }
                + p {
                  font-size: palette-font-size-level(4);
                }
              }
              .slider-top-row-info {
                p {
                  font-size: palette-font-size-level(4);
                }
              }
            }
          }
          .slider-top-row-left-content {
            margin-left: 0;
          }
        }
      }
      @media screen and (max-width: 370px) {
        .slider-bottom-row-actions {
          .task-btn {
            margin-right: palette-space-level(5);
          }
          .doWin-button {
            margin-left: palette-space-level(5);
          }
          p {
            font-size: palette-font-size-level(2);
          }
        }
      }
      .slider-bottom-row-actions {
        padding-bottom: 4px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        @media screen and (max-width: 380px) {
          justify-content: space-between;
        }
        .slider-action-button:not(:last-child) {
          margin-right: palette-space-level(8);
        }
        .slider-action-button:not(:first-child) {
          margin-left: palette-space-level(8);
        }
        .icon-task {
          width: palette-space-level(24);
          height: palette-space-level(12);
        }
        .task-btn,
        .doWin-button {
          max-width: 150px;
          padding: 0;
        }
        .task-count {
          height: 24px;
          width: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-repeat: no-repeat;
          background-position: center;
          background-image: url('~@/assets/images/icons/count-holder.svg');
          background-size: contain;
          padding-right: 1px;
          &-0 {
            background-image: url('~@/assets/images/icons/count-holder0.svg');
          }
        }
      }
    }
  }
}
.purpleButton {
  @include bg-linear-gradient(
    to left,
    palette-color-level('fushia', '0') 0%,
    palette-color-level('purple', '40') 100%
  );
}
/deep/.doWin-button span {
  height: 24px;
}
.empty {
  &.task-btn {
    background: none;
    border: solid 1px #b4c2d3;
    &:after {
      background: none;
    }
    p,
    span {
      color: #b4c2d3;
    }
    svg {
      margin-right: 5px;
    }
    svg g {
      fill: #b4c2d3;
    }
    .doWin-count {
      border: solid 1px;
      border-radius: 50%;
    }
  }
  .task-count {
    border: solid 1px;
    border-radius: 50%;
    width: 23px;
    padding: 0;
    margin-left: 5px;
  }
}
.do-win-btn-text {
  margin-right: 5px;
  margin-left: 5px;
}
/deep/ .swiper-pagination-bullet {
  width: 9px;
  height: 9px;
  background-color: #23303d;
  opacity: 0.2;
  border: 1px solid #ffffff;
  &-active {
    width: 10px;
    height: 10px;
    background-color: #23303d;
    opacity: 1;
  }
}
</style>
