<template>
  <div @click="scrollToTop" class="btn-rollup" :class="[showButton ? 'btn-rollup-visible' : '']">
    <div>
      <VueIcon
        :iconName="icons.chevronUp.name"
        iconColor="#ffffff"
        :width="icons.chevronUp.width"
        :height="icons.chevronUp.height"
      ></VueIcon>
    </div>
  </div>
</template>
<script>
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
export default {
  name: 'BrandRollupButton',
  components: {
    VueIcon,
  },
  props: {
    anchorId: {
      type: String,
      required: true,
    },
    scrollElementId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showButton: false,
    };
  },
  computed: {
    icons() {
      return {
        chevronUp: ICON_VARIABLES.chevronUp,
      };
    },
  },
  methods: {
    scrollToTop() {
      document.getElementById(this.anchorId).scrollIntoView({ behavior: 'smooth' });
    },
  },
  mounted() {
    let elMain = document.getElementById(this.scrollElementId);
    if (elMain) {
      elMain.addEventListener('scroll', e => {
        if (e.target.scrollTop > 200) {
          this.showButton = true;
        } else if (e.target.scrollTop < 150) {
          this.showButton = false;
        }
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.btn-rollup {
  position: fixed;
  overflow: hidden !important;
  z-index: 10;
  pointer-events: none;
  right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: calc(65px + #{$safe-area-inset-bottom});
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 0 8px #79838e65;
  background: radial-gradient(ellipse at center, #e5472d 60%, #e5472d10 100%);
  opacity: 0;
  transition: opacity 0.35s ease-in-out;
  &-visible {
    opacity: 1;
    pointer-events: auto;
  }
}
.body-stop-scrolling {
  height: 100%;
  position: fixed;
  overflow: hidden;
}
</style>
