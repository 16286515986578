<template>
  <div id="divFeedWrapper">
    <BrandRollupButton anchorId="divFeedWrapper" scrollElementId="mainAppEllement" />
    <BrandPartnerSlider />
    <div class="insider" id="inStory"></div>
    <div class="gtm-ad-slot" id="div-gpt-ad-1587047396774-0"></div>
    <BrandCompetitionSummary v-if="!isLampUser" />
    <RossetteCard v-if="!isImageRecognitionUser"></RossetteCard>
    <ImsakiyeCard v-if="!isImageRecognitionUser"></ImsakiyeCard>
    <BrandContentSlider swiperId="mainSliderFeed" />
    <MultiBannerSlider
      @onClickSliderContent="onClickSliderContent"
      @onSubSliderChanged="onSubSliderChanged"
      @initSubSlider="initSubSlider"
      @performSliderActions="performSliderActions"
    />
    <BrandTab
      idField="contentTypeId"
      nameField="contentName"
      :selectedTabId="this.selectedId"
      @select="selectTab($event)"
      :tabList="contentTypes"
    />
    <div class="insider" id="recommendationEngine"></div>
    <FeedList
      :eventTitle="`Ana Sayfa / ${selectedTabName}`"
      :feedItems="feeds"
      :infiniteLoader="getMoreFeed"
      @nextpage="getFeeds(true)"
      @marathonEvents="marathonEvents"
    />
  </div>
</template>
<script>
import BrandRollupButton from '@/components/brand/BrandRollupButton/BrandRollupButton.vue';
import FeedList from '@/components/brand/FeedList/FeedList.vue';
import BrandPartnerSlider from '@/components/brand/Slider/BrandPartnerSlider.vue';
import BrandContentSlider from '@/components/brand/Slider/BrandContentSlider.vue';
import ImsakiyeCard from '@/components/ImsakiyeCard/ImsakiyeCard.vue';
import { COMPONENT_SIZES } from '@/constants/component.constants.js';
import { Common, Content } from '@/services/Api/index';
import 'swiper/dist/css/swiper.css';
import StorageProps from '@/mixins/storageProps.js';
import gtmUtils from '@/mixins/gtmUtils.js';
import BrandCompetitionSummary from '@/components/brand/BrandCompetitionSummary/BrandCompetitionSummary.vue';
import BrandTab from '@/components/brand/BrandTab/BrandTab.vue';
import { mapGetters } from 'vuex';
import MultiBannerSlider from '@/components/brand/Slider/MultiBannerSlider.vue';
import RossetteCard from '../../../components/RossetteCard/RossetteCard.vue';
import StorageHelper from '@/utils/storageHelper';

export default {
  name: 'Feed',
  mixins: [StorageProps, gtmUtils],
  components: {
    FeedList,
    BrandContentSlider,
    BrandPartnerSlider,
    BrandTab,
    BrandCompetitionSummary,
    BrandRollupButton,
    MultiBannerSlider,
    ImsakiyeCard,
    RossetteCard,
  },
  data() {
    return {
      lastIndex: 0,
      pageSize: 10,
      getMoreFeed: true,
      selectedId: null,
      contentTypes: [],
      feeds: [],
      selectedTabName: '',
      viewed_promotions: [],
    };
  },
  beforeCreate() {
    this.$store.dispatch('app/setActiveNavItem', 'home');
  },
  methods: {
    setPopupGAEvent(value, eventData) {
      if (value) {
        this.pushDataLayerEvent('pop-up', eventData);
      }
    },
    marathonEvents(isMarathon) {
      this.setPopupGAEvent(isMarathon, {
        event: 'cEvent',
        category: 'Pop Up',
        action: 'Click',
        label: 'Ödül Yağmuru',
        value: 0,
        isNonInteraction: false,
      });
    },
    onClickSliderContent(banner) {
      this.pushDataLayerEvent('clickContent', banner);
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('select_promotion', banner);
    },
    performSliderActions(banner) {
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('select_promotion', {
        creative: 'Banner',
        bannerId: banner.bannerId,
        title: banner.title,
        link: banner.link,
      });
    },
    initSubSlider(banners) {
      setTimeout(() => {
        let mappedEventData = [];
        banners.forEach(m => {
          if (!this.viewed_promotions.includes(m.bannerId)) {
            this.pushDataLayerEvent('ecommerce');
            this.pushDataLayerEvent('view_promotion', {
              creative: 'Subbanner',
              bannerId: m.bannerId,
              title: m.title,
              link: m.link,
            });
            this.viewed_promotions.push(m.bannerId);
          }
          mappedEventData.push({
            id: m.bannerId,
            name: m.title,
            creative: m.imageURL,
            position: m.position,
          });
        });

        this.pushDataLayerEvent('changedContent', mappedEventData);
      }, 2000);
    },
    onSubSliderChanged(banner) {
      this.pushDataLayerEvent('changedContent', {
        id: banner.bannerId,
        name: banner.title,
        creative: banner.imageURL,
        position: banner.position,
      });
      if (!this.viewed_promotions.includes(banner.bannerId)) {
        this.pushDataLayerEvent('ecommerce');
        this.pushDataLayerEvent('view_promotion', {
          creative: 'Subbanner',
          bannerId: banner.bannerId,
          title: banner.title,
          link: banner.link,
        });
      }
    },
    selectTab(item) {
      this.selectedId = item.contentTypeId;
      this.$store.dispatch('app/setSelectedContentType', item);
      this.selectedTabName = item.contentName;
      this.lastIndex = 0;
      this.pushDataLayerEvent('filterContent', { filterName: item.contentName });
      document.querySelector('.main-inner-tab-bar').scrollTop = 175;
      this.$store.dispatch('app/setLastPosition', 175);
      this.getFeeds();
    },
    getFeeds(isNextPage) {
      Common.getFeeds(this.lastIndex, this.pageSize, this.selectedId).then(res => {
        const {
          Data: { feeds },
        } = res.data;
        if (isNextPage) {
          if (feeds.length < this.pageSize) {
            this.getMoreFeed = false;
          }
          this.feeds.push(...feeds);
        } else {
          this.feeds = feeds;
        }
        this.$store.dispatch('app/setFeeds', this.feeds);
        this.lastIndex = this.feeds.length;
      });
    },

    onIframeClick() {
      this.pushDataLayerEvent('videoPlayerStart', {
        videoPlayerStart: true,
        customerCode: this.getUserDetails?.customer_code,
      });
    },
  },
  created() {
    if (this.getContentTypes.length > 0) {
      this.contentTypes = this.getContentTypes;
      this.selectedId = this.getSelectedContentType.contentTypeId;
      this.selectedTabName = this.getSelectedContentType.contentName;
    } else {
      Content.getFeedFilters().then(res => {
        if (
          res.data &&
          res.data.Data &&
          res.data.Data.contentTypes &&
          res.data.Data.contentTypes.length > 0
        ) {
          const {
            Data: { contentTypes },
          } = res.data;
          this.$store.dispatch('app/setContentTypes', contentTypes);
          this.contentTypes = contentTypes;
          this.selectedId = this.contentTypes[0].contentTypeId;
          this.selectedTabName = this.contentTypes[0].contentName;
        }
      });
    }
    this.lastIndex = this.getFeedsData ? this.feeds.length : this.lastIndex;
    this.getFeeds();
    this.setAppBarTitle(`Merhaba ${this.currentUser.name}`);
  },
  mounted() {
    let isClicked = false;
    let onClick = () => {
      this.onIframeClick();
    };
    var eventListener = window.addEventListener('blur', function() {
      if (!isClicked && document.activeElement === document.getElementById('iframe')) {
        isClicked = true;
        onClick();
        setTimeout(function() {
          window.focus();
        }, 0);
      }
      window.removeEventListener('blur', eventListener);
    });
    //initialize google ads for homepage
    this.pushAds('large');
    var storyDiv = document.querySelector('#inStory');
    if (storyDiv) {
      document.body.addEventListener('click', event => {
        if (storyDiv.contains(event.target)) {
          this.pushDataLayerEvent('story');
        }
      });
    }
    setTimeout(() => {
      if (this.getLastPosition > 0)
        document.querySelector('.main-inner-tab-bar').scrollTop = this.getLastPosition;
    }, 100);
  },
  // beforeRouteLeave(to, from, next) {
  //   this.$store.dispatch(
  //     'app/setLastPosition',
  //     document.querySelector('.main-inner-tab-bar').scrollTop - 175,
  //   );
  //   next();
  // },
  computed: {
    ...mapGetters('app', [
      'getContentTypes',
      'getFeedsData',
      'getLastPosition',
      'getSelectedContentType',
    ]),
    ...mapGetters('auth', ['getUserDetails']),
    sizes() {
      return COMPONENT_SIZES;
    },
    isImageRecognitionUser() {
      const user = new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_KEY }).get();
      return user?.isImageRecognitionUser;
    },
  },
};
</script>

<style lang="scss" scoped>
.feed-list {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    padding: 0 0 15px 0;
  }
}
.iframe {
  @media screen and (max-width: 1024px) {
    height: 54vw !important;
  }
}
</style>
