var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.summary)?_c('div',{staticClass:"marathon-summary-wrapper",class:[_vm.summaryOpen ? 'summary-open' : ''],style:({
    'background-image':
      _vm.summary.gameType === 15
        ? 'linear-gradient(182deg, #ffa959 -23.76%, #ae4726 86.73%)'
        : _vm.summary.gameType === 18
        ? 'linear-gradient(81deg, #ae23a6, #e82762 98%)'
        : 'linear-gradient(81deg, #ea4c89, #e47b49 98%)',
  })},[_c('div',{staticClass:"marathon-summary-wrapper-collapsible",on:{"click":_vm.showSummary}},[_c('VueText',{attrs:{"sizeLevel":"5","weightLevel":"3","color":"white-100"}},[_vm._v(_vm._s(_vm.summary.surveyName))]),_c('VueIcon',{staticClass:"marathon-summary-wrapper-collapsible-caret",class:[_vm.summaryOpen ? 'caret-down' : ''],attrs:{"width":_vm.icons.right.width,"height":_vm.icons.right.height,"iconName":_vm.icons.right.name,"color":"#ffffff"}})],1),_c('div',{staticClass:"summary-area"},[_c('ul',{staticClass:"summary-area-list"},[_c('li',[_c('VueText',{attrs:{"sizeLevel":"4","color":"white-100"}},[_vm._v("Sıralaman")]),_c('VueText',{attrs:{"sizeLevel":"4","color":"white-100"}},[_vm._v(_vm._s(_vm.summary.rank))])],1),_c('li',[_c('VueText',{attrs:{"sizeLevel":"4","color":"white-100"}},[_vm._v("Katılımcı Sayısı")]),_c('VueText',{attrs:{"sizeLevel":"4","color":"white-100"}},[_vm._v(_vm._s(_vm.summary.attenderCount))])],1),_c('li',[_c('VueText',{attrs:{"sizeLevel":"4","color":"white-100"}},[_vm._v("Doğru Sayısı")]),_c('VueText',{attrs:{"sizeLevel":"4","color":"white-100"}},[_vm._v(_vm._s(_vm.summary.correctAnswer))])],1),_c('li',[_c('VueText',{attrs:{"sizeLevel":"4","color":"white-100"}},[_vm._v("Yanlış Sayısı")]),_c('VueText',{attrs:{"sizeLevel":"4","color":"white-100"}},[_vm._v(_vm._s(_vm.summary.wrongAnswer))])],1),_c('li',[_c('VueText',{attrs:{"sizeLevel":"4","color":"white-100"}},[_vm._v("Kazanılan Puan")]),_c('VueText',{attrs:{"sizeLevel":"4","color":"white-100"}},[_vm._v(_vm._s(_vm.summary.point))])],1)]),_c('button',{staticClass:"summary-area-button",on:{"click":_vm.goToPlayWin}},[_c('VueText',{attrs:{"sizeLevel":"4","weightLevel":"3"}},[_vm._v("DETAYLARI GÖR")])],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }