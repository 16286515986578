<template>
  <div class="multi-banner-container" v-if="banners.length">
    <swiper :options="sliderOptions" ref="swiperId" @slideChange="slideChanged">
      <swiper-slide
        v-for="(b, index) in banners"
        :key="getBannerKey(b, index)"
        :class="getBannerKey(b, index)"
      >
        <div v-if="b.isDynamicBanner">
          <div :style="getStyleDynamicDescription(b)" class="banner-dynamic-content">
            {{ b.description }}
          </div>
          <img
            v-if="b.subImage"
            :src="b.subImage"
            :style="getStyleDynamicSubImage(b)"
            class="banner-dynamic-content"
            alt="pm-aktif-logo"
          />
        </div>
        <div id="brandMediaContentBox">
          <BrandMediaContentBox
            :mediaSrc="b.image"
            :href="b.link"
            :bannerItem="b"
            :isSliderClick="true"
            @onClickSliderContent="onClickSliderContent(b)"
            @contentImageSize="contentImageSize"
          />
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import BrandMediaContentBox from '@/components/brand/BrandMediaContentBox/BrandMediaContentBox.vue';
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
import { Common, JourneyAction } from '@/services/Api/index';
import banner from '@/mixins/banner.js';
import { ACTION_TYPE, JOURNEY_ITEM_TYPE } from '@/constants/journey.constants.js';

export default {
  name: 'MultiBannerSlider',
  components: {
    swiper,
    swiperSlide,
    BrandMediaContentBox,
  },
  mixins: [banner],
  data() {
    return {
      banners: [],
      position: 0,
      stashedBanners: [],
      initBanners: [],
      sliderOptions: {
        slidesPerView: '2.3',
        spaceBetween: 10,
      },
      webParentBanner: {
        width: 260,
        height: 350,
      },
      appParentBanner: {
        width: 0,
        height: 0,
      },
    };
  },
  mounted() {
    Common.getMultiBannerData().then(res => {
      if (res.data && res.data.Data) {
        const dynamicBanners = res.data.Data.dynamicBanners.map(banner => ({
          ...banner,
          image: banner.bannerImage,
          isDynamicBanner: true,
          bannerId: banner.id || banner.bannerId,
        }));

        const regularBanners = res.data.Data.banners.map(banner => ({
          ...banner,
          image: banner.image,
          isDynamicBanner: false,
          bannerId: banner.id || banner.bannerId,
        }));

        const combinedBanners = [...regularBanners, ...dynamicBanners];

        combinedBanners.sort((a, b) => a.order - b.order);

        this.banners = combinedBanners.map((banner, index) => ({
          ...banner,
          position: index + 1,
        }));

        this.stashedBanners = this.banners.filter(banner => banner.position < 6);

        this.banners = combinedBanners.map(m => ({
          ...m,
          position: ++this.position,
        }));
        this.banners.forEach(f => {
          if (f.position < 4) {
            this.stashedBanners.push(f);
          }
        });

        this.initBanners = this.banners.slice(0, 2);
        this.$emit('initSubSlider', this.initBanners);
      }
    });
  },
  watch: {
    banners: {
      handler() {
        if (this.banners?.length > 0) {
          setTimeout(() => {
            this.banners.forEach((banner, index) => {
              const el = document.querySelector(`.${this.getBannerKey(banner, index)}`);

              const intersectionObserver = new IntersectionObserver(entries => {
                // If intersectionRatio is 0, the target is out of view
                // and we do not need to do anything.
                if (entries[0].intersectionRatio <= 0) return;

                JourneyAction.journeyActionLog({
                  journeyItemId: banner.bannerId,
                  journeyItemType: JOURNEY_ITEM_TYPE.SUB_BANNER,
                  actionType: ACTION_TYPE.VIEW,
                }).catch(error => {
                  console.error('Error logging banner view:', error);
                });
              });
              // start observing
              intersectionObserver.observe(el);
            });
          }, 100);
        }
      },
    },
  },
  methods: {
    getBannerKey(banner, index) {
      return `multi-${banner.bannerId}-${index}`;
    },
    onClickSliderContent(bannerItem) {
      this.$emit('onClickSliderContent', { ...bannerItem, position: ++this.position });
      this.sendClickBannerLog(bannerItem);
    },
    sendClickBannerLog(banner) {
      JourneyAction.journeyActionLog({
        journeyItemId: banner.bannerId,
        journeyItemType: JOURNEY_ITEM_TYPE.SUB_BANNER,
        actionType: ACTION_TYPE.CLICK,
      }).catch(error => {
        console.error('Error logging banner click:', error);
      });
    },
    slideChanged() {
      const previousIndex = this.$refs['swiperId'].swiper.previousIndex;
      const activeIndex = this.$refs['swiperId'].swiper.activeIndex;
      const displayPanners = this.banners.slice(previousIndex + 2, activeIndex + 2);

      const swiperSlides = Array.from(this.$refs['swiperId'].swiper.slides);
      const activeBanners = this.banners[this.banners.length - 4].position;
      const swiper = this.$refs['swiperId'].swiper;

      displayPanners.forEach(f => {
        this.$emit('onSubSliderChanged', f);
      });

      swiperSlides.forEach((m, i) => {
        if (m.className == 'swiper-slide swiper-slide-next' && i == activeBanners) {
          this.$emit('onSubSliderChanged', this.banners[this.banners.length - 1]);
        }
      });

      if (swiper.activeIndex == 0) {
        this.$emit('initSubSlider', this.initBanners);
      }

      this.performSliderActions(swiper);
    },
    performSliderActions(swiper) {
      const { isEnd, isBeginning } = swiper;

      if (isEnd) {
        this.$emit('performSliderActions', this.banners[this.banners.length - 1]);
      }

      if (isBeginning) {
        this.$emit('performSliderActions', this.banners[0]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.multi-banner-container {
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
}
/deep/.swiper-wrapper {
  .swiper-slide {
    position: relative;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    .clickable {
      cursor: pointer;
    }
    .bottom-text {
      position: absolute;
      height: 52px;
      bottom: 3px;
      left: 0;
      right: 0;
      color: #fff;
      font-size: 14px;
      padding: 22px 10px 14px;
      line-height: 16px;
      background: linear-gradient(0deg, rgba(35, 48, 61, 0.6) 0%, rgba(35, 48, 61, 0) 100%);
    }
  }
}
.banner-dynamic-content {
  position: absolute;
  z-index: 10;
}
</style>
