import { render, staticRenderFns } from "./BrandPartnerSlider.vue?vue&type=template&id=6839dccc&scoped=true&"
import script from "./BrandPartnerSlider.vue?vue&type=script&lang=js&"
export * from "./BrandPartnerSlider.vue?vue&type=script&lang=js&"
import style0 from "./BrandPartnerSlider.vue?vue&type=style&index=0&id=6839dccc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6839dccc",
  null
  
)

export default component.exports