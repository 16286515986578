<template>
  <div
    v-if="summary"
    class="marathon-summary-wrapper"
    :class="[summaryOpen ? 'summary-open' : '']"
    :style="{
      'background-image':
        summary.gameType === 15
          ? 'linear-gradient(182deg, #ffa959 -23.76%, #ae4726 86.73%)'
          : summary.gameType === 18
          ? 'linear-gradient(81deg, #ae23a6, #e82762 98%)'
          : 'linear-gradient(81deg, #ea4c89, #e47b49 98%)',
    }"
  >
    <div class="marathon-summary-wrapper-collapsible" @click="showSummary">
      <VueText sizeLevel="5" weightLevel="3" color="white-100">{{ summary.surveyName }}</VueText>
      <VueIcon
        class="marathon-summary-wrapper-collapsible-caret"
        :class="[summaryOpen ? 'caret-down' : '']"
        :width="icons.right.width"
        :height="icons.right.height"
        :iconName="icons.right.name"
        color="#ffffff"
      ></VueIcon>
    </div>
    <div class="summary-area">
      <ul class="summary-area-list">
        <li>
          <VueText sizeLevel="4" color="white-100">Sıralaman</VueText>
          <VueText sizeLevel="4" color="white-100">{{ summary.rank }}</VueText>
        </li>
        <li>
          <VueText sizeLevel="4" color="white-100">Katılımcı Sayısı</VueText>
          <VueText sizeLevel="4" color="white-100">{{ summary.attenderCount }}</VueText>
        </li>
        <li>
          <VueText sizeLevel="4" color="white-100">Doğru Sayısı</VueText>
          <VueText sizeLevel="4" color="white-100">{{ summary.correctAnswer }}</VueText>
        </li>
        <li>
          <VueText sizeLevel="4" color="white-100">Yanlış Sayısı</VueText>
          <VueText sizeLevel="4" color="white-100">{{ summary.wrongAnswer }}</VueText>
        </li>
        <li>
          <VueText sizeLevel="4" color="white-100">Kazanılan Puan</VueText>
          <VueText sizeLevel="4" color="white-100">{{ summary.point }}</VueText>
        </li>
      </ul>
      <button class="summary-area-button" @click="goToPlayWin">
        <VueText sizeLevel="4" weightLevel="3">DETAYLARI GÖR</VueText>
      </button>
    </div>
  </div>
</template>
<script>
import { Gamification } from '@/services/Api/index';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import { ICON_VARIABLES } from '@/constants/component.constants';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesRoot from '@/router/routes/RoutesRoot';
import VueText from '@/components/shared/VueText/VueText.vue';
export default {
  name: 'BrandCompetitionSummary',
  components: {
    VueText,
    VueIcon,
  },
  data() {
    return {
      summary: null,
      summaryOpen: false,
    };
  },
  computed: {
    icons() {
      return ICON_VARIABLES;
    },
  },
  created() {
    Gamification.getMarathonSummary().then(res => {
      if (res.data && res.data.Data && res.data.Data.history) {
        this.summary = res.data.Data.history;
      } else {
        //mock data
        // this.summary = {
        //   surveyName: 'Bil Bakalım / 22.05.2020',
        //   rank: '9000',
        //   attenderCount: 14292,
        //   correctAnswer: 9583,
        //   wrongAnswer: 4709,
        //   point: 50,
        //   gameType: 18,
        // };
      }
    });
  },
  methods: {
    showSummary() {
      this.summaryOpen = !this.summaryOpen;
    },
    goToPlayWin() {
      this.$router.push(`${RoutesRoot.Secure.path}/${RoutesSecure.PlayAndWin.path}`);
    },
  },
};
</script>
<style lang="scss" scoped>
.marathon-summary-wrapper {
  width: 100%;
  height: 50px;
  transition: height 0.5s ease-out;
  overflow: hidden;
  .summary-area {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &-list {
      list-style-type: none;
      padding: palette-space-level(5) palette-space-level(15) palette-space-level(0)
        palette-space-level(15);
      margin-bottom: palette-space-level(10);
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
      }
    }
    &-button {
      width: 150px;
      border-radius: 22px;
      border: 1.5px solid palette-color-level('purple', 10);
      background-color: palette-color-level('white', 100);
      margin: 0 auto;
      padding: palette-space-level(10) palette-space-level(20);
    }
  }
  &.summary-open {
    height: 275px;
  }
  &-collapsible {
    padding: palette-space-level(20) palette-space-level(20) palette-space-level(20)
      palette-space-level(15);
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-caret {
      transition: transform 0.3s ease-out;
      &.caret-down {
        transform: rotateZ(90deg);
      }
    }
  }
}
</style>
