<template>
  <div v-if="data.isVisible" :class="['container', expansionState]">
    <div class="container-header" @click="toggleExpansion">
      <span>Ramazan İmsakiyesi</span>
      <VueIcon
        :width="icons.ramadanLanterns.width"
        :height="icons.ramadanLanterns.height"
        :iconName="icons.ramadanLanterns.name"
        color="#cdcdcd"
      ></VueIcon>
    </div>
    <div class="container-content">
      <div class="container-content-left">
        <div class="container-content-left-location">
          <div class="container-content-left-location-city">{{ data.city }}</div>
          <div class="container-content-left-location-date">{{ data.date }}</div>
        </div>
        <div class="container-content-left-prayerTimes">
          <div
            v-for="(item, index) in leftPrayerTimes"
            :key="index"
            class="container-content-left-prayerTimes-time"
          >
            <div class="container-content-left-prayerTimes-time-icon">
              <VueIcon
                :width="item.icon.width"
                :height="item.icon.height"
                :iconName="item.icon.name"
                color="#fff"
              ></VueIcon>
              <div>{{ item.label }}:</div>
            </div>
            <div>{{ item.time }}</div>
          </div>
        </div>
      </div>

      <div class="container-content-divider"></div>

      <div class="container-content-right">
        <div
          v-for="(item, index) in rightPrayerTimes"
          :key="index"
          class="container-content-right-time"
        >
          <div class="container-content-right-time-icon">
            <VueIcon
              :width="item.icon.width"
              :height="item.icon.height"
              :iconName="item.icon.name"
              color="#ffffff"
            ></VueIcon>
            <div>{{ item.label }}:</div>
          </div>
          <div>{{ item.time }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Common } from '@/services/Api/index';
import gtmUtils from '@/mixins/gtmUtils.js';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import { ICON_VARIABLES } from '@/constants/component.constants';

const PRAYER_LABELS = {
  IMSAK: 'İmsak',
  IFTAR: 'İftar',
  GUNES: 'Güneş',
  OGLE: 'Öğle',
  IKINDI: 'İkindi',
  AKSAM: 'Akşam',
  YATSI: 'Yatsı',
};

export default {
  name: 'ImsakiyeCard',
  components: {
    VueIcon,
  },
  mixins: [gtmUtils],
  data() {
    return {
      data: {
        isVisible: false,
        city: '',
        date: '',
        actionTimes: {
          imsak: '',
          iftar: '',
        },
        times: {
          gunes: '',
          ogle: '',
          ikindi: '',
          aksam: '',
          yatsi: '',
        },
      },
      expansionStatus: {
        isExpanded: false,
        hasBeenExpanded: false,
      },
    };
  },
  computed: {
    icons() {
      return ICON_VARIABLES;
    },
    leftPrayerTimes() {
      return [
        {
          label: PRAYER_LABELS.IMSAK,
          time: this.data.actionTimes.imsak,
          icon: this.icons.moonStarOutline,
        },
        {
          label: PRAYER_LABELS.IFTAR,
          time: this.data.actionTimes.iftar,
          icon: this.icons.moonStarSolid,
        },
      ];
    },
    rightPrayerTimes() {
      return [
        {
          label: PRAYER_LABELS.GUNES,
          time: this.data.times.gunes,
          icon: this.icons.sunRise,
        },
        {
          label: PRAYER_LABELS.OGLE,
          time: this.data.times.ogle,
          icon: this.icons.sun,
        },
        {
          label: PRAYER_LABELS.IKINDI,
          time: this.data.times.ikindi,
          icon: this.icons.sunHorizon,
        },
        {
          label: PRAYER_LABELS.AKSAM,
          time: this.data.times.aksam,
          icon: this.icons.sunSet,
        },
        {
          label: PRAYER_LABELS.YATSI,
          time: this.data.times.yatsi,
          icon: this.icons.moon,
        },
      ];
    },
    expansionState() {
      return this.expansionStatus.isExpanded ? 'shown' : 'collapsed';
    },
  },
  created() {
    this.getImsakiyeData();
  },
  methods: {
    async getImsakiyeData() {
      try {
        const response = await Common.getImsakiye();
        if (response?.data?.Data) {
          this.data = response.data.Data;
        }
      } catch (error) {
        console.error('Failed to fetch Imsakiye data:', error);
      }
    },
    toggleExpansion() {
      this.expansionStatus.isExpanded = !this.expansionStatus.isExpanded;

      if (this.expansionStatus.isExpanded && !this.expansionStatus.hasBeenExpanded) {
        this.pushDataLayerEvent('imsakiye_card');
        this.expansionStatus.hasBeenExpanded = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  height: 206px;
  padding: 0px 16px;
  overflow: hidden;
  color: #fff;
  background: linear-gradient(#600d0c, #943f3e);
  transition: height 0.5s ease-out;
  &.collapsed {
    height: 50px;
    background: #600d0c;
  }
  &.shown {
    height: 206px;
    background: linear-gradient(#600d0c, #943f3e);
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    font-weight: bold;
    cursor: pointer;
  }
  &-content {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    height: calc(100% - 50px);
    padding-top: 8px;
    padding-bottom: 16px;
    &-left {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 50%;
      &-location {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        &-city {
          font-size: 16px;
        }
        &-date {
          font-size: 12px;
        }
      }
      &-prayerTimes {
        display: flex;
        flex-direction: column;
        &-time {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 12px;
          &-icon {
            display: flex;
            align-items: center;
            gap: 6px;
          }
        }
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
      width: 50%;
      &-time {
        display: flex;
        justify-content: space-between;
        align-items: center;

        font-size: 12px;
        &-icon {
          display: flex;
          align-items: center;
          gap: 6px;
        }
      }
    }
    &-divider {
      width: 1px;
      align-self: stretch;
      background: rgba(255, 255, 255, 0.3);
    }
  }
}
</style>
